import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"
import PageSection from "../components/pageSection"
import UrlButton from "../components/urlButton"
import Styles from "../styles/resume.module.scss"
import SubHeader from "../components/subHeader"

const Resume = () => {
  const {
    contentfulPortfolio: { portfolio: file },
  } = useStaticQuery(graphql`
    query {
      contentfulPortfolio {
        portfolio {
          file {
            fileName
            url
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={"Resume Daniel Lupascu"} />
      <PageSection first>
        <SubHeader emoji={"📋"}>Resume</SubHeader>
        <div className={Styles.buttonResume}>
          <UrlButton url={file.file.url} text={"Download resume"} />
          <object data={"https:" + file.file.url} type={"application/pdf"}>
            Resume
          </object>
        </div>
      </PageSection>
    </Layout>
  )
}

export default Resume
